import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import IconCard from "../../Components/IconCard";
import styles from "../../styles/home.module.scss";
import keycloakService from "../../modules";
import { ReactSVG } from "react-svg";
import AddInfo from "../User/AddInfo";
import EditInfo from "../User/EditInfo";
import React, { useEffect, useState } from "react";
import { GetUserCourses } from "../../axios/apis/Courses";
import { GetUserData } from "../../axios/apis/Users";
import cardData from '../../modules/applicationLinks';

export default function Home() {
  const [courseData, setCourseData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isEditModalVisible, setEditModalVisibility] = useState(false);

  useEffect(() => {
    GetUserCourses()
      .then((result) => {
        setCourseData(result);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleButtonClick = () => {
    GetUserData()
      .then((result) => {
        setUserData(result);
        setEditModalVisibility(true);
      })
      .catch((error) => {
        if (error.status === 404) {
        }
      });
  };
  const handleCloseModal = () => {
    setEditModalVisibility(false);
  };
  return (
    <>
      <section className="py-5 py-md-6 py-lg-8 py-xl-10">
        <Container>
          <div className="d-flex align-items-center justify-content-end gap-2 mb-4 mb-md-5">
            <span className="text-primary fw-light fs-6">
              Hi,{" "}
              <strong className="fw-semibold">
                {userData?.candidateName || "User"}
              </strong>{" "}
            </span>

            {/* <OverlayTrigger overlay={<Tooltip>Edit profile</Tooltip>}>
              <Button
                type="button"
                variant="primary"
                className="cet_auth_btn"
                onClick={handleButtonClick}
              >
                <ReactSVG
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/edit_profile.svg`}
                />
              </Button>
            </OverlayTrigger> */}

            <OverlayTrigger overlay={<Tooltip>Sign out</Tooltip>}>
              <Button
                onClick={() => keycloakService.doLogout()}
                type="button"
                variant="primary"
                className="cet_auth_btn"
              >
                <ReactSVG
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/logout.svg`}
                />
              </Button>
            </OverlayTrigger>
          </div>
          <Row className="row-gap-6">
            <Col xs={12} lg={5}>
              <div
                className={`position-relative mx-auto mx-lg-0 ${styles.cet_about_image}`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/about_image.jpg`}
                  alt="About"
                />
              </div>
            </Col>
            <Col xs={12} lg={7}>
              <Row className="justify-content-center row-gap-3 row-gap-md-4">
                {cardData?.map((item, index) => (
                  <Col xs={12} md={6} key={index}>
                    <IconCard cardData={item} course={courseData} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
        <AddInfo setUserData={setUserData}></AddInfo>
        {/* {isEditModalVisible && (
          <EditInfo
            userData={userData}
            showEditModal={isEditModalVisible}
            onHide={handleCloseModal}
          ></EditInfo>
        )} */}
      </section>
    </>
  );
}
